import cn from 'classnames';

const MAX_BULLETS = 8;

const renderCustomBullets = (swiper, current, total) => {
  const start = Math.floor((current - 1) / MAX_BULLETS) * MAX_BULLETS;
  const end = Math.min(start + MAX_BULLETS, total);

  const bullets = [];

  for (let i = start; i < end; i++) {
    const { bulletClass, bulletActiveClass } = swiper.params.pagination;
    const classNames = [bulletClass];

    if (i === current - 1) {
      classNames.push(bulletActiveClass);
    }

    bullets.push(`<span class="${cn(...classNames)}"></span>`);
  }

  return bullets.join('');
};

export default renderCustomBullets;
