import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
import ReactModal from 'react-modal';

import { noop } from '../../../utils';

// reset default modal styles
ReactModal.defaultStyles.overlay = {};
ReactModal.defaultStyles.content = {};

ReactModal.setAppElement('#modal');

const ModalBase = forwardRef(({
  onAfterClose: onAfterCloseProps = noop,
  onAfterOpen: onAfterOpenProps = noop,
  ...props
}, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => modalRef.current, [modalRef]);

  const onAfterClose = useCallback(() => {
    enableBodyScroll(modalRef.current);

    onAfterCloseProps();
  }, [modalRef, onAfterCloseProps]);

  const onAfterOpen = useCallback(() => {
    /* -- ios hack -- */
    const storedRequestAnimationFrame = window.requestAnimationFrame;
    window.requestAnimationFrame = () => 42; // disable render
    /* -- ios hack -- */

    disableBodyScroll(modalRef.current);

    /* -- ios hack -- */
    window.requestAnimationFrame = storedRequestAnimationFrame; // enable render
    /* -- ios hack -- */

    onAfterOpenProps();
  }, [modalRef, onAfterOpenProps]);

  const setOverlayRef = useCallback((overlayRef) => {
    // ref is reset before closing the modal
    if (overlayRef) {
      modalRef.current = overlayRef;
    }
  }, []);

  return (
    <ReactModal
      overlayRef={setOverlayRef}
      onAfterClose={onAfterClose}
      onAfterOpen={onAfterOpen}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
});

ModalBase.propTypes = {
  children: PropTypes.any,
  onAfterClose: PropTypes.func,
  onAfterOpen: PropTypes.func
};

export default memo(ModalBase);
