import Swiper, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Keyboard
} from 'swiper';

Swiper.use([
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Keyboard
]);
