import { useEffect, useRef, useState } from 'react';

const useMounted = (state) => {
  const mountedRef = useRef(false);
  const [mountedState, setMountedState] = useState(false);

  useEffect(() => {
    mountedRef.current = true;
    setMountedState(true);

    return () => {
      mountedRef.current = false;
      setMountedState(false);
    };
  }, []);

  if (state) {
    return mountedState;
  }

  return mountedRef;
};

export default useMounted;
