import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Call from './icons/Call';
import Check from './icons/Check';
import Close from './icons/Close';
import CloseBold from './icons/CloseBold';
import Email from './icons/Email';
import Gallery from './icons/Gallery';
import Phone from './icons/Phone';
import Telegram from './icons/Telegram';
import Whatsapp from './icons/Whatsapp';

const ICONS = {
  Call,
  Check,
  Close,
  CloseBold,
  Email,
  Gallery,
  Phone,
  Telegram,
  Whatsapp
};

const Icon = ({
  className,
  name,
  color = '#000',
  width,
  height
}) => {
  const Element = ICONS[name];

  if (!Element) {
    return null;
  }

  return (
    <Element
      className={className}
      color={color}
      width={width}
      height={height}
    />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf(Object.keys(ICONS)),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Icon);
