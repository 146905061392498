import React from 'react';
import { createRoot } from 'react-dom/client';

import Fab from '../../components/Fab';

const initFab = (data) => {
  const container = document.getElementById('ssr-fab');

  if (container) {
    const root = createRoot(container);
    root.render(<Fab data={data} />);
  }
};

export default initFab;
