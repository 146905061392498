import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  closeOverlay,
  showOverlay
} from '../../utils/overlay';

const DESKTOP_WIDTH = 1360;

const getWindowWidth = () => (
  window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth
);

const Sidebar = () => {
  const [opened, setOpened] = useState(false);
  const hamburger = useRef();
  const overlay = useRef();
  const aside = useRef();

  const onCloseClick = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }

    setOpened(false);
  }, []);

  const onWindowResize = useCallback(() => {
    if (opened && (getWindowWidth() > DESKTOP_WIDTH)) {
      onCloseClick();
    }
  }, [
    opened,
    onCloseClick
  ]);

  useEffect(() => {
    hamburger.current = document.getElementById('tcon');
    aside.current = document.querySelector('aside');
    overlay.current = document.getElementById('overlay');

    const onToggleClick = (event) => {
      if (event) {
        event.preventDefault();
      }

      setOpened((prevOpened) => !prevOpened);
    };

    if (hamburger.current) {
      hamburger.current.addEventListener('click', onToggleClick);
    }

    return () => {
      if (hamburger.current) {
        hamburger.current.removeEventListener('click', onToggleClick);
      }
    };
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', onWindowResize);
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [
    onWindowResize
  ]);

  useEffect(() => {
    if (opened) {
      hamburger.current.classList.add('tcon-transform');
      document.body.classList.add('is-open');
      showOverlay(aside.current, onCloseClick);
    } else {
      hamburger.current.classList.remove('tcon-transform');
      document.body.classList.remove('is-open');
      closeOverlay(aside.current, onCloseClick);
    }
  }, [
    opened,
    onCloseClick
  ]);

  return null;
};

export default memo(Sidebar);
