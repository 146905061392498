import React, {
  forwardRef,
  memo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';

const InputText = forwardRef(({
  className = undefined,
  type = 'text',
  onChange = noop,
  ...props
}, ref) => {
  if (type === 'textarea') {
    return (
      <textarea
        ref={ref}
        className={cn('form-input', className)}
        onChange={onChange}
        {...props}
      />
    );
  }

  return (
    <input
      ref={ref}
      className={cn('form-input', className)}
      type={type}
      onChange={onChange}
      {...props}
    />
  );
});

InputText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'text',
    'textarea'
  ]),
  onChange: PropTypes.func
};

export default memo(InputText);
