import React from 'react';
import { createRoot } from 'react-dom/client';

import FriendFormModal from '../../components/Modals/FriendFormModal';

const initModalFriend = (options, lang, objectId) => {
  const container = document.getElementById(options.root);
  const root = createRoot(container);

  root.render(
    <FriendFormModal
      lang={lang}
      objectId={objectId}
      button={options.button}
      modal={options.modal}
      content={options.content}
    />
  );
};

export default initModalFriend;
