import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { getSearch } from '../../api';
import useApiCall from '../../hooks/useApiCall';
import useDebounce from '../../hooks/useDebounce';
import { regexpRef } from '../../utils/tools';

import Button from '../UI/Button';
import TextField from '../UI/InputText';
import ResultCard from '../ResultCard';
import SROnly from '../UI/SROnly';

const SearchMobile = ({
  lang,
  placeholder
}) => {
  const [result, setResult] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 250);

  const onSearch = useCallback(() => {
    const options = { q: debouncedSearchTerm, lang };
    return getSearch(options);
  }, [debouncedSearchTerm, lang]);

  const onSuccess = useCallback((response) => {
    setResult(response.data?.result);
  }, []);

  const onChange = useCallback((event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (!value.toLowerCase().match(regexpRef)) {
      setResult(null);
    }
  }, []);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    if (result) {
      window.location.assign(result.url);
    }
  }, [result]);

  const {
    processing,
    callback
  } = useApiCall(onSearch, onSuccess);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const hasError = !processing && !result
    && searchTerm === debouncedSearchTerm
    && debouncedSearchTerm.match(regexpRef);

  return (
    <form
      className="navigation-search"
      onSubmit={onSubmit}
    >
      <div className="form-field">
        <SROnly
          component="label"
          htmlFor="search_mobile"
        >
          {placeholder}
        </SROnly>

        <TextField
          className={cn('navigation-search-input', { error: hasError })}
          name="search_mobile"
          id="search_mobile"
          placeholder={placeholder}
          value={searchTerm}
          onChange={onChange}
        />
      </div>

      <Button
        className="navigation-search-button"
        type="submit"
      />

      {!!result && (
        <ResultCard house={result} />
      )}
    </form>
  );
};

SearchMobile.propTypes = {
  lang: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default memo(SearchMobile);
