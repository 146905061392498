import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

const openedClass = 'dropdown-language-opened';

const LangMenu = () => {
  const [opened, setOpened] = useState(false);

  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  const onMenuToggle = useCallback(() => {
    setOpened((prevValue) => !prevValue);
  }, []);

  const onOutsideClick = useCallback(({ target }) => {
    if (!menuRef.current.contains(target)) {
      setOpened(false);
    }
  }, []);

  useEffect(() => {
    menuRef.current = document.querySelector('#dropdown-language');

    if (menuRef.current) {
      menuButtonRef.current = menuRef.current.querySelector('button');
      menuButtonRef.current.addEventListener('click', onMenuToggle);
    }

    return () => {
      if (menuRef.current) {
        menuButtonRef.current.removeEventListener('click', onMenuToggle);
      }
    };
  }, [
    onMenuToggle
  ]);

  useEffect(() => {
    if (opened) {
      menuRef.current.classList.add(openedClass);
      document.addEventListener('click', onOutsideClick);
    } else {
      menuRef.current.classList.remove(openedClass);
      document.removeEventListener('click', onOutsideClick);
    }
  }, [
    opened,
    onOutsideClick
  ]);

  return null;
};

export default memo(LangMenu);
