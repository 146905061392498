import cn from 'classnames';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { object, string, boolean } from 'yup';
import { useFormik } from 'formik';

import { postShareFriend } from '../../../api';
import { noop } from '../../../utils';
import { submitHandler } from '../../../utils/forms';

import Button from '../../UI/Button';
import InputBool from '../../UI/InputBool';
import TextField from '../../UI/InputText';
import SROnly from '../../UI/SROnly';

const validationSchema = object().shape({
  your_name: string().max(128).required(),
  your_email: string().email().required(),
  friend_name: string().max(128).required(),
  friend_email: string().email().required(),
  agreement: boolean().oneOf([true])
});

const initialValues = {
  your_name: '',
  your_email: '',
  friend_name: '',
  friend_email: '',
  agreement: false
};

const TIMEOUT = 10000;

const FriendForm = ({
  lang,
  objectId,
  content,
  onSuccess: onSuccessProps = noop
}) => {
  const [message, setMessage] = useState('');
  const timeoutRef = useRef();

  const showSuccessMessage = useCallback(() => {
    if (content.message) {
      setMessage(content.message);

      timeoutRef.current = setTimeout(() => {
        setMessage('');
        timeoutRef.current = null;
      }, TIMEOUT);
    }
  }, [content]);

  const onSuccess = useCallback((values, helper) => {
    helper.resetForm();
    showSuccessMessage();
    onSuccessProps();
  }, [onSuccessProps, showSuccessMessage]);

  const {
    errors,
    isSubmitting,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (form, helper) => {
      const onSend = () => postShareFriend(form, lang, objectId);
      return submitHandler(form, helper, onSend, onSuccess);
    }
  });

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return (
    <form
      className="form friend-form"
      onSubmit={handleSubmit}
    >
      <div className="form-field">
        <SROnly
          component="label"
          htmlFor="your_name"
        >
          {content.your_name}
        </SROnly>

        <TextField
          autoComplete="off"
          className={cn({ error: touched.your_name && errors.your_name })}
          id="your_name"
          name="your_name"
          placeholder={content.your_name}
          value={values.your_name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="form-field">
        <SROnly
          component="label"
          htmlFor="your_email"
        >
          {content.your_email}
        </SROnly>

        <TextField
          autoComplete="off"
          className={cn({ error: touched.your_email && errors.your_email })}
          id="your_email"
          name="your_email"
          placeholder={content.your_email}
          value={values.your_email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="form-field">
        <SROnly
          component="label"
          htmlFor="friend_name"
        >
          {content.friend_name}
        </SROnly>

        <TextField
          autoComplete="off"
          className={cn({ error: touched.friend_name && errors.friend_name })}
          id="friend_name"
          name="friend_name"
          placeholder={content.friend_name}
          value={values.friend_name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="form-field">
        <SROnly
          component="label"
          htmlFor="friend_email"
        >
          {content.friend_email}
        </SROnly>

        <TextField
          autoComplete="off"
          className={cn({ error: touched.friend_email && errors.friend_email })}
          id="friend_email"
          name="friend_email"
          placeholder={content.friend_email}
          value={values.friend_email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="form-field">
        <InputBool
          className={cn({ error: touched.agreement && errors.agreement })}
          id="agreement"
          name="agreement"
          label={(
            <>
              {content.agreement?.label}
              {' '}
              <a
                href={content.agreement?.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {content.agreement?.page}
              </a>
              {' '}
              {content.agreement?.site}
            </>
          )}
          checked={values.agreement}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      {!!message && (
        <div className="form-message">
          {message}
        </div>
      )}

      {!!errors.nonFieldError && (
        <div className="form-error">
          {errors.nonFieldError}
        </div>
      )}

      <div className="form-button">
        <Button
          className="gold-solid"
          disabled={isSubmitting}
          type="submit"
        >
          {content.button}
        </Button>
      </div>
    </form>
  );
};

FriendForm.propTypes = {
  lang: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    your_name: PropTypes.string.isRequired,
    your_email: PropTypes.string.isRequired,
    friend_name: PropTypes.string.isRequired,
    friend_email: PropTypes.string.isRequired,
    agreement: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
      page: PropTypes.string,
      site: PropTypes.string
    }),
    button: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired,
  onSuccess: PropTypes.func
};

export default memo(FriendForm);
