const initAccordions = () => {
  const accordions = document.querySelectorAll('.list-item');

  Array.prototype.forEach.call(accordions, (accordion) => {
    const accordionButton = accordion.querySelector('button');
    const accordionContent = accordion.querySelector('.list-item-content');
    const accordionWrap = accordion.querySelector('.item-content-wrap');

    accordionButton.addEventListener('click', () => {
      let height = 0;

      if (!accordion.classList.contains('list-item-opened')) {
        height = accordionWrap.offsetHeight;
      }

      accordion.classList.toggle('list-item-opened');
      accordionContent.style.height = `${height}px`;
    });
  });
};

export default initAccordions;
