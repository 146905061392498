import { init } from '@sentry/react';

import { DOMReady } from './utils';
import initPages from './pages';
import initGlobal from './pages/global';

import './slider';

const initSentry = (sentryDSN) => init({
  dsn: sentryDSN,
  blacklistUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extensions:\/\//i
  ],
  tracesSampleRate: 0.1
});

DOMReady(() => {
  // eslint-disable-next-line no-underscore-dangle
  const config = window.__config__;

  if (config.sentryDSN) {
    initSentry(config.sentryDSN);
  }

  initPages(config);
  initGlobal(config);
});
