import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const InputBool = ({
  className,
  error,
  id,
  label,
  ...props
}) => (
  <label
    className={cn('form-checkbox', { error }, className)}
    htmlFor={props.id}
  >
    <input
      className="form-checkbox-input"
      id={id}
      type="checkbox"
      {...props}
    />

    <span className="form-checkbox-check">
      <Icon name="Check" />
    </span>

    <span className="form-checkbox-label">
      {label}
    </span>
  </label>
);

InputBool.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node
};

export default memo(InputBool);
