/* eslint-disable global-require */

import valuation from './valuation';
import blog from './blog';
import home from './home';
import destination from './destination';
import object from './object';
import post from './post';
import saleRental from './saleRental';
import contacts from './contacts';
import notFound from './notFound';

const pages = {
  valuation,
  blog,
  home,
  destination,
  object,
  post,
  saleRental,
  contacts,
  notFound
};

const initPages = (config) => {
  const name = Object.keys(pages).find((page) => config[page]);

  if (name) {
    pages[name](config);
  }
};

export default initPages;
