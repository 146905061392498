/* globals google */
import { loadScript } from '../../utils';
import { styles } from './mapStyles';

const initContactsMap = (lang, options) => {
  const {
    apiKey,
    markerIcon,
    markerPosition
  } = options;

  window.onMapLoaded = () => {
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 10,
      center: markerPosition,
      scrollwheel: false,
      styles,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: markerPosition,
      map,
      icon: {
        url: markerIcon,
        size: new google.maps.Size(28, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 50)
      }
    });
  };

  const url = `//maps.googleapis.com/maps/api/js?key=${apiKey}&language=${lang}&callback=onMapLoaded`;

  loadScript('googleMap', url);
};

export default initContactsMap;
