import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Telegram = ({
  className,
  color = '#000',
  width = 24,
  height = 24
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      // eslint-disable-next-line max-len
      d="M1.47 10.64S12.1 6.3 15.77 4.75c1.4-.61 6.2-2.57 6.2-2.57s2.2-.86 2.02 1.22c-.06.86-.55 3.87-1.04 7.12l-1.54 9.63s-.12 1.41-1.16 1.66c-1.05.24-2.76-.86-3.07-1.1-.25-.19-4.6-2.95-6.2-4.3-.43-.37-.92-1.1.06-1.96A234.1 234.1 0 0 0 17.5 8.3c.73-.74 1.47-2.45-1.6-.37-4.36 3-8.65 5.83-8.65 5.83s-.98.62-2.82.06c-1.84-.55-4-1.29-4-1.29s-1.46-.92 1.05-1.9Z"
      fill={color}
    />
  </svg>
);

Telegram.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Telegram);
