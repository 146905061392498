/* globals gtag */
import initOrderForm from '../shared/orderForm';
import initAccordions from './accordion';
import initValuationTabs from './tabs';

export default ({ valuation, content }) => {
  initAccordions();
  initValuationTabs(valuation.slider);

  const onSuccess = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'send_request', { event_category: 'valuation' });
    }
  };

  initOrderForm(content.order, '', onSuccess);
};
