import Swiper from 'swiper';

import renderCustomBullets from '../shared/bullets';

const initSliderMain = (slider) => {
  // eslint-disable-next-line no-new
  new Swiper(`${slider.elementId} .swiper`, {
    total: slider.slides,
    preloadImages: false,
    pagination: {
      el: `${slider.elementId} .swiper-pagination`,
      type: 'custom',
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      renderCustom: renderCustomBullets
    },
    navigation: {
      nextEl: `${slider.elementId} .swiper-button-next`,
      prevEl: `${slider.elementId} .swiper-button-prev`
    },
    slidesPerView: 1,
    keyboard: true,
    loop: true,
    speed: 400,
    breakpoints: {
      1024: {
        speed: 800
      }
    }
  });

  const contactButton = document.getElementById('object-slider-contact');
  const orderFormElement = document.getElementById('order-form');

  if (contactButton && orderFormElement) {
    contactButton.addEventListener('click', () => {
      orderFormElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    });
  }
};

export default initSliderMain;
