export const extractError = (errors) => {
  if (Array.isArray(errors)) {
    return errors[0];
  }

  return errors;
};

export const submitHandler = async(values, helpers, onSend, onSuccess, onError) => {
  const promise = onSend();

  // sync api call
  if (!(promise instanceof Promise)) {
    helpers.setSubmitting(false);
    return onSuccess(promise);
  }

  // async api call
  try {
    const result = await promise;
    helpers.setSubmitting(false);

    return onSuccess(values, helpers, result);
  } catch (error) {
    if (error.response) {
      const keys = Object.keys(error.response?.data || {});

      const errors = keys.reduce((prev, next) => ({
        ...prev,
        [next]: extractError(error.response.data[next])
      }), {});

      // DRF battery don't support remmaping fields
      if (typeof errors.detail !== 'undefined') {
        errors.nonFieldErrors = errors.detail;
        delete errors.detail;
      }

      helpers.setErrors(errors);
    } else if (error.request) {
      // eslint-disable-next-line no-console
      console.log('No response received:', error);
    } else {
      // eslint-disable-next-line no-console
      console.log('Something went wrong:', error);
    }

    helpers.setSubmitting(false);

    return onError(values, helpers, error);
  }
};
