import Swiper from 'swiper';

const initSliderBlog = (id) => {
  // eslint-disable-next-line no-new
  new Swiper(`${id} .swiper`, {
    preloadImages: false,
    navigation: {
      nextEl: `${id} .swiper-button-next`,
      prevEl: `${id} .swiper-button-prev`
    },
    pagination: {
      el: `${id} .swiper-pagination`,
      clickable: true
    },
    loop: true,
    loopedSlides: 0
  });
};

export default initSliderBlog;
