import React from 'react';
import { createRoot } from 'react-dom/client';

import ObjectSliderModal from '../../components/Modals/ObjectSliderModal';

const initSliderModal = (options) => {
  const container = document.querySelector(options.elementId);
  const root = createRoot(container);

  root.render(
    <ObjectSliderModal
      buttonId={options.buttonId}
      slides={options.slides}
      title={options.title}
      gallery={options.gallery}
    />
  );
};

export default initSliderModal;
