import LangMenu from '../../components/LangMenu';
import Cookie from '../../components/Cookie';
import Sidebar from '../../components/Sidebar';
import initSearchMobile from '../home/searchMobile';
import { renderComponent } from '../../utils/tools';

import initFab from './fab';
import initGAEvents from './events';
import initSubscribeForm from './subscribe';

const initGlobal = ({ fab, lang }) => {
  initGAEvents();
  initFab(fab);
  renderComponent(LangMenu);
  renderComponent(Cookie);
  renderComponent(Sidebar);
  initSearchMobile(lang);
  initSubscribeForm(lang);
};

export default initGlobal;
