/* globals gtag */

const initEvents = () => {
  const phoneHeader = document.querySelector('[data-ga="phone-header"]');
  if (phoneHeader) {
    phoneHeader.addEventListener('click', () => {
      if (typeof gtag === 'function') {
        gtag('event', 'call', { event_category: 'phone' });
      }
    });
  }

  const phoneAside = document.querySelector('[data-ga="phone-aside"]');
  if (phoneAside) {
    phoneAside.addEventListener('click', () => {
      if (typeof gtag === 'function') {
        gtag('event', 'call', { event_category: 'phone' });
      }
    });
  }

  const phoneFooter = document.querySelector('[data-ga="phone-footer"]');
  if (phoneFooter) {
    phoneFooter.addEventListener('click', () => {
      if (typeof gtag === 'function') {
        gtag('event', 'call', { event_category: 'phone_footer' });
      }
    });
  }

  const whatsappFooter = document.querySelector('[data-ga="whatsapp-footer"]');
  if (whatsappFooter) {
    whatsappFooter.addEventListener('click', () => {
      if (typeof gtag === 'function') {
        gtag('event', 'click', { event_category: 'whatsapp_footer' });
      }
    });
  }

  const emailFooter = document.querySelector('[data-ga="email-footer"]');
  if (emailFooter) {
    emailFooter.addEventListener('click', () => {
      if (typeof gtag === 'function') {
        gtag('event', 'click', { event_category: 'email_footer' });
      }
    });
  }
};

export default initEvents;
