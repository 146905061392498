/* globals gtag */
import initOrderFormModal from '../shared/orderFormModal';
import initSliderCards from '../shared/sliderCards';
import initSearchDesktop from './searchDesktop';
import initSliderHome from './slider';
import initContentWrappers from './wrapper';

export default ({ home, lang }) => {
  initSliderHome(home.slider);

  initSearchDesktop(lang, home.translations, home.cities.sale, home.cities.rental);

  home.sliders.forEach((slider) => {
    initSliderCards(slider.id, slider.count);
  });

  initContentWrappers();

  const onSuccess = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'send_request', {
        event_category: 'home'
      });
    }
  };

  initOrderFormModal(home.order, onSuccess);
};
