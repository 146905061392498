import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchMobile from '../../components/SearchMobile';

export default (lang) => {
  const container = document
    .getElementById('ssr-search-mobile');
  const root = createRoot(container);

  const placeholder = container
    .querySelector('input')
    .getAttribute('placeholder');

  root.render(
    <SearchMobile
      lang={lang}
      placeholder={placeholder}
    />
  );
};
