import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Gallery = ({
  className,
  color = '#000',
  width = 24,
  height = 24
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      // eslint-disable-next-line max-len
      d="M5 7h2V5H5v2Zm6 12h2v-2h-2v2Zm-6 0h2v-2H5v2Zm0-6h2v-2H5v2Zm6 0h2v-2h-2v2Zm6-8v2h2V5h-2Zm-6 2h2V5h-2v2Zm6 6h2v-2h-2v2Zm0 6h2v-2h-2v2Z"
      fill={color}
    />
  </svg>
);

Gallery.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Gallery);
