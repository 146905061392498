import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { localStorage } from './index';

export const regexpRef = /^((ref|hr)\s*:?\s*)?(\d){4}$/;
export const regexpEmail = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const renderComponent = (Element, props = {}, selector = '') => {
  let container;

  if (selector) {
    container = document.querySelector(selector);
  } else {
    container = document.createElement('div');
    document.body.appendChild(container);
  }

  const root = createRoot(container);
  // eslint-disable-next-line react/jsx-props-no-spreading
  root.render(<StrictMode><Element {...props} /></StrictMode>);
};

export const reloadMobileHandler = () => {
  let mobile = localStorage.getItem('mobile') === String(true);
  localStorage.setItem('mobile', mobile.toString());

  window.addEventListener('resize', () => {
    const width = window.innerWidth;
    const desktopWidth = 1024;

    if (width <= desktopWidth && !mobile) {
      mobile = true;
      localStorage.setItem('mobile', String(mobile));
      window.location.reload();
    } else if (width > desktopWidth && mobile) {
      mobile = false;
      localStorage.setItem('mobile', String(mobile));
      window.location.reload();
    }
  });
};

export const appendToList = (selector, items) => {
  const housesList = document.querySelector(selector);
  const { className } = housesList.querySelector('li');

  (items || []).forEach((html) => {
    const liElement = document.createElement('li');
    liElement.className = className;
    liElement.innerHTML = html;
    housesList.appendChild(liElement);
  });
};
