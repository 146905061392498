import storageFactory from './storageFactory';

export const localStorage = storageFactory('localStorage');

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const noop = () => {};

export const noopPromise = () => Promise.resolve({});

export const isNumber = (str) => !Number.isNaN(Number(str));

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const loadScript = (id, url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        script.id = id;

        if (typeof callback === 'function') {
          callback();
        }
      }
    };
  } else {
    script.onload = () => {
      script.id = id;

      if (typeof callback === 'function') {
        callback();
      }
    };
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const DOMReady = (callback) => {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    callback();
    return;
  }

  if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
    return;
  }

  if (document.attachEvent) {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState !== 'loading') {
        callback();
      }
    });
  }
};
