import { useEffect } from 'react';

/**
 * @param {Element | String} selector
 * @param {Function} callback
 * @param {String} event
 */
const useElementEvent = (selector, callback, event = 'click') => {
  useEffect(() => {
    let element = selector;

    if (typeof selector === 'string') {
      element = document.querySelector(selector);
    }

    element?.addEventListener(event, callback);

    return () => {
      element?.removeEventListener(event, callback);
    };
  });
};

export default useElementEvent;
