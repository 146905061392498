const storageFactory = (provider) => {
  let inMemoryStorage = {};

  const isSupported = () => {
    try {
      const testKey = '__local_storage_test_key__';
      window[provider].setItem(testKey, testKey);
      window[provider].removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const clear = () => {
    if (isSupported()) {
      window[provider].clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name) => {
    if (isSupported()) {
      return window[provider].getItem(name);
    }

    if (inMemoryStorage.hasOwnProperty(name)) { // eslint-disable-line no-prototype-builtins
      return inMemoryStorage[name];
    }

    return null;
  };

  const key = (index) => {
    if (isSupported()) {
      return window[provider].key(index);
    }

    return Object.keys(inMemoryStorage)[index] || null;
  };

  const removeItem = (name) => {
    if (isSupported()) {
      window[provider].removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (name, value) => {
    if (isSupported()) {
      window[provider].setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value);
    }
  };

  const length = () => {
    if (isSupported()) {
      return window[provider].length;
    }

    return Object.keys(inMemoryStorage).length;
  };

  const storage = {
    getItem,
    setItem,
    removeItem,
    clear,
    key
  };

  Object.defineProperty(storage, 'length', { get: length });

  return storage;
};

export default storageFactory;
