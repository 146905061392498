import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Email = ({
  className,
  color = '#000',
  width = 24,
  height = 24
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      // eslint-disable-next-line max-len
      d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"
    />
  </svg>
);

Email.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Email);
