import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchDesktop from '../../components/SearchDesktop';

export default (lang, translations, sale, rental) => {
  const container = document
    .getElementById('ssr-search-home');
  const root = createRoot(container);

  const placeholder = container
    .querySelector('input')
    .getAttribute('placeholder');

  root.render(
    <SearchDesktop
      lang={lang}
      sale={sale}
      rental={rental}
      translations={translations}
      placeholder={placeholder}
    />
  );
};
