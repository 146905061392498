import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SROnly = ({
  children,
  className,
  component: Tag = 'span',
  ...props
}) => (
  <Tag
    className={cn('sr-only', className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Tag>
);

SROnly.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string
};

export default memo(SROnly);
