import React from 'react';
import { createRoot } from 'react-dom/client';

import SubscribeForm from '../../components/Forms/SubscribeForm';

const initSubscribe = (lang) => {
  const container = document.getElementById('ssr-subscribe');
  const root = createRoot(container);

  const field = container.querySelector('input');
  const fieldPlaceholder = field.getAttribute('placeholder');

  const label = container.querySelector('label');
  const fieldLabel = label.textContent;

  const button = container.querySelector('button span');
  const buttonLabel = button.textContent;

  root.render(
    <SubscribeForm
      buttonLabel={buttonLabel}
      fieldLabel={fieldLabel}
      fieldPlaceholder={fieldPlaceholder}
      lang={lang}
    />
  );
};

export default initSubscribe;
