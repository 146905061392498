import React from 'react';
import { createRoot } from 'react-dom/client';

import ObjectVideoModal from '../../components/Modals/ObjectVideoModal';

const initSliderModal = (options) => {
  const container = document.querySelector(options.elementId);
  const root = createRoot(container);

  root.render(
    <ObjectVideoModal
      buttonId={options.buttonId}
      videoUrl={options.videoUrl}
    />
  );
};

export default initSliderModal;
