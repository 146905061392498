import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../utils';

const ObjectGallery = ({
  slides = [],
  title = '',
  onClick = noop
}) => (
  <>
    <h1 className="content-subtitle object-gallery-title">
      {slides.length} {title}
    </h1>

    <ul className="row object-gallery-list">
      {slides.map((slide, index) => (
        <li
          key={slide.src}
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12 object-gallery-item"
        >
          <button
            className="object-gallery-button"
            type="button"
            onClick={() => onClick(slide, index)}
          >
            <img
              alt={slide.alt}
              className="object-gallery-image"
              draggable="false"
              loading="lazy"
              src={slide.src}
              srcSet={slide.srcSet}
            />
          </button>
        </li>
      ))}
    </ul>
  </>
);

ObjectGallery.propTypes = {
  slides: PropTypes.array,
  title: PropTypes.string,
  onClick: PropTypes.func
};

export default memo(ObjectGallery);
