/* globals gtag */
import { reloadMobileHandler, renderComponent } from '../../utils/tools';
import initOrderForm from '../shared/orderForm';
import initFilters from './filters';
import ObjectsLoader from './loader';

export default ({ lang, saleRental, content }) => {
  initFilters(lang, saleRental);

  const { type, selected } = saleRental;
  renderComponent(ObjectsLoader, { lang, type, selected });

  // init order form
  const onSuccess = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'send_request', { event_category: saleRental.type });
    }
  };

  initOrderForm(content.order, '', onSuccess);

  // reload on screen resize
  reloadMobileHandler();
};
