import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

export const showOverlay = (element, onClick) => {
  const overlay = document.getElementById('overlay');
  const layers = parseInt(overlay.dataset.layers, 10) || 0;
  overlay.dataset.layers = `${layers + 1}`;
  overlay.addEventListener('click', onClick);
  disableBodyScroll(element);

  if (layers === 0) {
    overlay.classList.add('active');
  }
};

export const closeOverlay = (element, onClick) => {
  const overlay = document.getElementById('overlay');
  const layers = parseInt(overlay.dataset.layers, 10) || 0;
  overlay.dataset.layers = `${Math.max(layers - 1, 0)}`;
  overlay.removeEventListener('click', onClick);
  enableBodyScroll(element);

  if (layers === 1) {
    overlay.classList.remove('active');
  }
};
