/* globals gtag */
import initOrderForm from '../shared/orderForm';

import initMap from './map';

const initContacts = ({ lang, contacts, content }) => {
  initMap(lang, contacts, 2000);

  const onSuccess = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'send_request', { event_category: 'contacts' });
    }
  };

  initOrderForm(content.order, '', onSuccess);
};

export default initContacts;
