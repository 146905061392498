import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Check = ({
  className,
  width = 10,
  height = 10
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 10"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.04 1.8a1 1 0 0 1 0 1.4L4.46 7.8a1 1 0 0 1-1.42 0L.96 5.71a1 1 0 1 1 1.41-1.42l1.38 1.38 3.88-3.88a1 1 0 0 1 1.41 0Z"
      fill="currentColor"
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Check);
