/* globals gtag */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { closeOverlay, showOverlay } from '../../utils/overlay';
import useBooleanState from '../../hooks/useBooleanState';

import Icon from '../UI/Icon';

const Fab = ({
  data = {}
}) => {
  const rootRef = useRef();

  const {
    isOpen,
    close,
    toggle
  } = useBooleanState();

  const onActionClick = useCallback((target) => {
    close();

    if (typeof gtag === 'function') {
      gtag('event', 'click', { event_category: target });
    }
  }, [close]);

  useEffect(() => {
    if (isOpen) {
      showOverlay(rootRef.current, close);
    } else {
      closeOverlay(rootRef.current, close);
    }
  }, [
    isOpen,
    close
  ]);

  const links = useMemo(() => ([
    {
      icon: <Icon name="Email" width={24} height={24} />,
      href: `mailto:${data.email}`,
      label: 'Email',
      onClick: () => onActionClick('fab_email')
    },
    {
      icon: <Icon name="Whatsapp" width={28} height={28} />,
      href: data.whatsapp,
      label: 'WhatsApp',
      onClick: () => onActionClick('fab_whatsapp')
    },
    {
      icon: <Icon name="Telegram" width={24} height={24} />,
      href: data.telegram,
      label: 'Telegram',
      onClick: () => onActionClick('fab_telegram')
    },
    {
      icon: <Icon name="Phone" width={24} height={24} />,
      href: `tel:${data.phone}`,
      label: 'Phone',
      onClick: () => onActionClick('fab_phone')
    }
  ]), [
    data,
    onActionClick
  ]);

  return (
    <div
      ref={rootRef}
      className={cn('rtf', { open: isOpen, closed: !isOpen })}
    >
      <div className="rtf--mb__c">
        <button
          aria-label="Floating menu"
          aria-pressed={String(isOpen)}
          className="rtf--mb"
          tabIndex="0"
          type="button"
          onClick={toggle}
        >
          <div className="rtf--mb-i">
            <Icon
              name="Call"
              className="rtf--mb-o"
              color="#FFF"
              width={28}
              height={28}
            />

            <Icon
              name="CloseBold"
              className="rtf--mb-c"
              color="#FFF"
              width={28}
              height={28}
            />
          </div>
        </button>

        <ul>
          {links.map(({
            href,
            icon,
            label,
            onClick
          }) => (
            <li
              key={label}
              className="rtf--ab__c"
            >
              <a
                className="rtf--ab"
                aria-label={label}
                aria-hidden={!isOpen}
                href={href}
                rel="noopener noreferrer"
                tabIndex={isOpen ? 0 : -1}
                target="_blank"
                title={label}
                onClick={onClick}
              >
                {icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Fab.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    telegram: PropTypes.string,
    whatsapp: PropTypes.string
  })
};

export default memo(Fab);
