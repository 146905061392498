import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Close = ({
  className,
  color = '#000',
  width = 24,
  height = 24
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      // eslint-disable-next-line max-len
      d="M4.73 4 4 4.73l7.28 7.25L4 19.27l.73.73 7.25-7.28L19.27 20l.73-.73-7.28-7.29L20 4.73 19.27 4l-7.29 7.28L4.73 4Z"
    />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default memo(Close);
