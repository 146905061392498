import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = forwardRef(({
  className,
  type = 'button',
  ...props
}, ref) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={cn('btn', className)}
    ref={ref}
    // eslint-disable-next-line react/button-has-type
    type={type}
    {...props}
  />
));

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
};

export default memo(Button);
