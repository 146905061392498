import React, {
  memo,
  useCallback,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import useElementEvent from '../../../hooks/useElementEvent';
import useBooleanState from '../../../hooks/useBooleanState';

import FriendForm from '../../Forms/FriendForm';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import Icon from '../../UI/Icon';

const CLOSE_TIMEOUT = 10000;

const FriendFormModal = ({
  lang,
  objectId,
  button,
  content
}) => {
  const timeout = useRef(undefined);

  const {
    isOpen,
    open: openModal,
    close: closeModal
  } = useBooleanState();

  const onSuccess = useCallback(() => {
    timeout.current = setTimeout(closeModal, CLOSE_TIMEOUT);
  }, [
    closeModal
  ]);

  useElementEvent(`#${button}`, openModal);

  useEffect(() => {
    if (!isOpen) {
      clearTimeout(timeout.current);
    }
  }, [isOpen]);

  return (
    <Modal
      className="share-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="share-modal-content">
        <h3 className="content-subtitle">
          {content.title}
        </h3>

        <FriendForm
          lang={lang}
          objectId={objectId}
          content={content}
          onSuccess={onSuccess}
        />
      </div>

      <Button
        aria-label="Close"
        className="ReactModal__Close"
        onClick={closeModal}
      >
        <Icon
          color="#afafaf"
          name="Close"
          width={32}
          height={32}
        />
      </Button>
    </Modal>
  );
};

FriendFormModal.propTypes = {
  lang: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
};

export default memo(FriendFormModal);
