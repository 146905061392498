/* eslint-disable consistent-return */
import { useCallback, useState } from 'react';

import { noop } from '../utils';

import useMounted from './useMounted';

const useApiCall = (
  onSend = noop,
  onSuccess = noop,
  onFail = noop,
  onValidate = noop
) => {
  const mounted = useMounted();

  const [processing, setProcessing] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);

  const callback = useCallback(async(event) => {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    if (processing) {
      return;
    }

    const validate = onValidate();

    if (validate) {
      return;
    }

    setProcessing(true);
    setApiErrors(null);

    const promise = onSend();

    // sync api call
    if (!(promise instanceof Promise)) {
      if (mounted.current) {
        setProcessing(false);
      }

      return onSuccess(promise);
    }

    // async api call
    try {
      const action = await promise;
      return onSuccess(action);
    } catch (error) {
      if (mounted.current && error?.response) {
        setApiErrors(error.response.data || null);
      }

      return onFail(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  }, [
    mounted,
    processing,
    onFail,
    onSend,
    onSuccess,
    onValidate
  ]);

  return {
    apiErrors,
    processing,
    callback
  };
};

export default useApiCall;
