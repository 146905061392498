import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useBooleanState from '../../../hooks/useBooleanState';

import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import useElementEvent from '../../../hooks/useElementEvent';
import Icon from '../../UI/Icon/Icon';

const ObjectVideoModal = ({
  buttonId,
  videoUrl
}) => {
  const {
    isOpen,
    open: openModal,
    close: closeModal
  } = useBooleanState();

  useElementEvent(buttonId, openModal);

  return (
    <Modal
      overlayClassName="object-modal-overlay"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="object-video-iframe">
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className="object-modal-wrapper">
        <Button
          aria-label="Close"
          className="ReactModal__Close"
          onClick={closeModal}
        >
          <Icon
            name="Close"
            width={32}
            height={32}
            color="#FFF"
          />
        </Button>
      </div>
    </Modal>
  );
};

ObjectVideoModal.propTypes = {
  buttonId: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired
};

export default memo(ObjectVideoModal);
