import cn from 'classnames';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { object, string, boolean } from 'yup';
import { useFormik } from 'formik';

import { postOrder } from '../../../api';
import { submitHandler } from '../../../utils/forms';
import { noop } from '../../../utils';

import Button from '../../UI/Button';
import InputBool from '../../UI/InputBool';
import InputText from '../../UI/InputText';
import SROnly from '../../UI/SROnly';

const validationSchema = object().shape({
  name: string().max(128).required(),
  email: string().email().required(),
  phone: string().max(32).required(),
  text: string().max(8192).required(),
  agreement: boolean().oneOf([true])
});

const initialValues = {
  name: '',
  email: '',
  phone: '',
  text: '',
  agreement: false
};

const TIMEOUT = 10000;

const OrderForm = ({
  objectId = null,
  content,
  onSuccess: onSuccessProps = noop
}) => {
  const [message, setMessage] = useState('');
  const timeoutRef = useRef();

  const showSuccessMessage = useCallback(() => {
    if (content.message) {
      setMessage(content.message);

      timeoutRef.current = setTimeout(() => {
        setMessage('');
        timeoutRef.current = null;
      }, TIMEOUT);
    }
  }, [content]);

  const onSuccess = useCallback((values, helpers) => {
    helpers.resetForm();
    showSuccessMessage();
    onSuccessProps();
  }, [onSuccessProps, showSuccessMessage]);

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const {
    errors,
    isSubmitting,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (form, helper) => {
      const onSend = () => postOrder(form, objectId);
      return submitHandler(form, helper, onSend, onSuccess);
    }
  });

  return (
    <form
      className="form order-form"
      onSubmit={handleSubmit}
    >
      <div className="form-row">
        <div className="form-col">
          <div className="form-field">
            <SROnly
              component="label"
              htmlFor="name"
            >
              {content.name}
            </SROnly>

            <InputText
              autoComplete="off"
              className={cn({ error: touched.name && errors.name })}
              id="name"
              name="name"
              placeholder={content.name}
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <SROnly
              component="label"
              htmlFor="email"
            >
              {content.email}
            </SROnly>

            <InputText
              autoComplete="off"
              className={cn({ error: touched.email && errors.email })}
              id="email"
              name="email"
              placeholder={content.email}
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <SROnly
              component="label"
              htmlFor="phone"
            >
              {content.phone}
            </SROnly>

            <InputText
              autoComplete="off"
              className={cn({ error: touched.phone && errors.phone })}
              id="phone"
              name="phone"
              placeholder={content.phone}
              value={values.phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-col">
          <div className="form-field">
            <SROnly
              component="label"
              htmlFor="text"
            >
              {content.text}
            </SROnly>

            <InputText
              autoComplete="off"
              className={cn({ error: touched.text && errors.text })}
              id="text"
              name="text"
              placeholder={content.text}
              type="textarea"
              value={values.text}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="form-field">
        <InputBool
          className={cn({ error: touched.agreement && errors.agreement })}
          id="agreement"
          name="agreement"
          label={(
            <>
              {content.agreement?.label}
              {' '}
              <a
                href={content.agreement?.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {content.agreement?.page}
              </a>
              {' '}
              {content.agreement?.site}
            </>
          )}
          checked={values.agreement}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      {!!message && (
        <div className="form-message">
          {message}
        </div>
      )}

      {!!errors.nonFieldError && (
        <div className="form-error">
          {errors.nonFieldError}
        </div>
      )}

      <div className="form-button">
        <Button
          className="gold-solid"
          disabled={isSubmitting}
          type="submit"
        >
          {content.button}
        </Button>
      </div>
    </form>
  );
};

OrderForm.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    agreement: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
      page: PropTypes.string,
      site: PropTypes.string
    })
  }).isRequired,
  objectId: PropTypes.string,
  onSuccess: PropTypes.func
};

export default memo(OrderForm);
