import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

import { isNumber } from '../../utils';

const modalClassName = 'object-modal-slider';

const ObjectSliderModal = ({
  active = null,
  slides = []
}) => {
  const swiperRef = useRef();

  const [index, setIndex] = useState(null);
  const realIndexRef = useRef(null);

  const onInit = useCallback((swiper) => {
    swiperRef.current = swiper;
  }, []);

  const onSlideChange = useCallback((swiper) => {
    realIndexRef.current = swiper.realIndex;
  }, []);

  useEffect(() => {
    if (isNumber(active)) {
      swiperRef.current?.slideToLoop(active, 0);
    }
  }, [active]);

  useEffect(() => {
    const { swiper } = document.querySelector('.swiper');
    setIndex(swiper.realIndex);

    swiper.keyboard.disable();

    return () => {
      swiper.keyboard.enable();
      swiper.slideToLoop(realIndexRef.current, 0);
    };
  }, []);

  if (index == null) {
    return null;
  }

  return (
    <div className={modalClassName}>
      <Swiper
        loop
        loopedSlides={2}
        navigation={{
          enabled: true,
          nextEl: `.${modalClassName} .swiper-button-next`,
          prevEl: `.${modalClassName} .swiper-button-prev`
        }}
        initialSlide={index}
        keyboard
        watchOverflow
        pagination={{
          enabled: true,
          el: `.${modalClassName} .swiper-pagination`,
          type: 'fraction'
        }}
        slidesPerView={1}
        modules={[
          Keyboard,
          Navigation,
          Pagination
        ]}
        onInit={onInit}
        onSlideChange={onSlideChange}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.src}>
            <img
              alt={slide.alt}
              className="swiper-slide-image"
              draggable="false"
              sizes="100vw"
              src={slide.src}
              srcSet={slide.srcSet}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
      <div className="swiper-pagination" />
    </div>
  );
};

ObjectSliderModal.propTypes = {
  active: PropTypes.number,
  slides: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string
  }))
};

export default ObjectSliderModal;
