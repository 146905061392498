import Swiper from 'swiper';

export default (id) => {
  const homeSwiper = new Swiper(`${id}.swiper`, {
    id,
    fadeEffect: {
      crossFade: true
    },
    preloadImages: false,
    slidesPerView: 1,
    navigation: {
      nextEl: `${id} .swiper-button-next`,
      prevEl: `${id} .swiper-button-prev`
    },
    effect: 'fade',
    speed: 800,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    keyboard: true,
    on: {
      slideChange: (swiper) => {
        if (swiper && !swiper.autoplay.running) {
          swiper.autoplay.start();
        }
      }
    }
  });

  if (homeSwiper) {
    homeSwiper.autoplay.start();
  }

  const slides = document.querySelectorAll(`${id} .swiper-slide`);

  Array.prototype.forEach.call(slides, (slide) => {
    const houseId = parseInt(slide.dataset.houseId, 10);
    const buttons = slide.querySelectorAll('button');
    const openClassName = 'is-opened';

    // open dropdown on home page and set slider on pause
    const onClick = () => {
      const homeDropDown = document.querySelector(`#home-dropdown-${houseId}`);

      if (homeDropDown.classList.contains(openClassName)) {
        homeSwiper.autoplay.start();
      } else {
        homeSwiper.autoplay.stop();
      }

      homeDropDown.classList.toggle(openClassName);
    };

    Array.prototype.forEach.call(buttons, (button) => {
      button.addEventListener('click', onClick);
    });
  });
};
