import Swiper from 'swiper';

import renderCustomBullets from './bullets';

const initSliderCards = (id, total) => {
  // eslint-disable-next-line no-new
  new Swiper(`#${id} .swiper`, {
    total,
    preloadImages: false,
    pagination: {
      el: `#${id} .swiper-pagination`,
      type: 'custom',
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      renderCustom: renderCustomBullets
    },
    navigation: {
      nextEl: `#${id} .swiper-button-next`,
      prevEl: `#${id} .swiper-button-prev`
    },
    spaceBetween: 20,
    speed: 400,
    runCallbacksOnInit: false,
    slidesPerView: 1,
    loop: total > 1,
    loopedSlides: 0,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        loop: total > 3
      },
      720: {
        slidesPerView: 2,
        loop: total > 2
      }
    }
  });
};

export default initSliderCards;
