import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import { getBlogPosts } from '../../api';
import useApiCall from '../../hooks/useApiCall';
import useElementEvent from '../../hooks/useElementEvent';
import { appendToList } from '../../utils/tools';

const PostsLoader = ({ lang }) => {
  const [page, setPage] = useState(2);

  const buttonRef = useRef(null);

  const onSuccess = useCallback((response) => {
    const { result } = response.data;
    appendToList('#posts-list', result.items);

    if (!result.has_next && buttonRef.current) {
      buttonRef.current.style.display = 'none';
    }

    setPage((prevPage) => prevPage + 1);
  }, []);

  const onSubmit = useCallback(() => {
    const options = { lang, page };
    return getBlogPosts(options);
  }, [lang, page]);

  const {
    callback,
    processing
  } = useApiCall(onSubmit, onSuccess);

  useEffect(() => {
    buttonRef.current = document.querySelector('#blog-load-more');
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.disabled = processing;
    }
  }, [processing]);

  useElementEvent(buttonRef.current, callback);

  return null;
};

PostsLoader.propTypes = {
  lang: PropTypes.string.isRequired
};

export default PostsLoader;
