/* globals gtag */
import React, {
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { postSubscribe } from '../../../api';
import { submitHandler } from '../../../utils/forms';

import Button from '../../UI/Button';
import TextField from '../../UI/InputText';
import SROnly from '../../UI/SROnly';

const validationSchema = object().shape({
  subscribe: string().email().required()
});

const initialValues = {
  subscribe: ''
};

const SubscribeForm = ({
  buttonLabel,
  fieldLabel,
  fieldPlaceholder,
  lang
}) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onHintClick = useCallback(() => setSuccessMessage(''), []);

  const onSuccess = useCallback((values, helpers, result) => {
    if (typeof gtag === 'function') {
      gtag('event', 'subscription', { event_category: 'newsletter' });
    }

    setErrorMessage('');
    setSuccessMessage(result.data?.message || '');

    setTimeout(() => setSuccessMessage(''), 10000);
  }, []);

  const onError = useCallback((values, helpers, result) => {
    setErrorMessage(result.data?.message || '');
  }, []);

  const {
    errors,
    isSubmitting,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (form, helper) => {
      const onSend = () => postSubscribe({ email: form.subscribe }, lang);
      return submitHandler(form, helper, onSend, onSuccess, onError);
    }
  });

  return (
    <form
      className="subscribe-form"
      noValidate
      onSubmit={handleSubmit}
    >
      <Button
        className={cn('subscribe-form-hint', {
          error: !!errorMessage,
          opened: errorMessage || successMessage
        })}
        type="button"
        onClick={onHintClick}
      >
        {errorMessage || successMessage}
      </Button>

      <SROnly
        component="label"
        htmlFor="subscribe"
      >
        {buttonLabel}
      </SROnly>

      <TextField
        autoComplete="off"
        aria-label={fieldLabel}
        className={cn({ error: touched.subscribe && errors.subscribe })}
        id="subscribe"
        name="subscribe"
        placeholder={fieldPlaceholder}
        value={values.subscribe}
        type="email"
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Button
        className="subscribe-form-submit"
        disabled={isSubmitting}
        type="submit"
      >
        <SROnly>
          {buttonLabel}
        </SROnly>
      </Button>
    </form>
  );
};

SubscribeForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};

export default memo(SubscribeForm);
