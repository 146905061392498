import Swiper from 'swiper';

import renderCustomBullets from '../shared/bullets';

const initSliderDestination = (id) => {
  // eslint-disable-next-line no-new
  new Swiper(`${id} .swiper`, {
    id,
    preloadImages: false,
    pagination: {
      el: `${id} .swiper-pagination`,
      type: 'custom',
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      renderCustom: renderCustomBullets
    },
    navigation: {
      nextEl: `${id} .swiper-button-next`,
      prevEl: `${id} .swiper-button-prev`
    },
    slidesPerView: 1,
    autoplay: {
      delay: 7000
    },
    keyboard: true,
    loop: true,
    speed: 400,
    breakpoints: {
      1024: {
        speed: 800
      }
    }
  });
};

export default initSliderDestination;
