const initContentWrappers = () => {
  const mobileWraps = document.querySelectorAll('.mobile-wrap');

  Array.prototype.forEach.call(mobileWraps, (container) => {
    const button = container.querySelector('button');

    button.addEventListener('click', () => {
      const content = container.querySelector('.mobile-wrap-content');
      const text = container.querySelector('.content-text');
      content.style.height = `${text.clientHeight}px`;

      setTimeout(() => {
        content.style.height = 'auto';
        button.style.display = 'none';
        container.classList.add('opened');
      }, 1000);
    });
  });
};

export default initContentWrappers;
