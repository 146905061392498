import React from 'react';
import { createRoot } from 'react-dom/client';

import OrderForm from '../../components/Forms/OrderForm';

const initOrderForm = (content, objectId, onSuccess) => {
  const container = document.getElementById('order-form');
  const root = createRoot(container);

  root.render(
    <OrderForm
      content={content}
      objectId={objectId}
      onSuccess={onSuccess}
    />
  );
};

export default initOrderForm;
