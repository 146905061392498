import React, {
  memo,
  useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import useBooleanState from '../../../hooks/useBooleanState';
import useElementEvent from '../../../hooks/useElementEvent';
import { noop } from '../../../utils';

import OrderForm from '../../Forms/OrderForm';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import Icon from '../../UI/Icon/Icon';

const CLOSE_TIMEOUT = 10000;

const OrderFormModal = ({
  button,
  content,
  onSuccess: onSuccessProps = noop
}) => {
  const timeout = useRef(undefined);

  const {
    isOpen,
    open: openModal,
    close: closeModal
  } = useBooleanState();

  const onSuccess = useCallback(() => {
    timeout.current = setTimeout(closeModal, CLOSE_TIMEOUT);

    onSuccessProps();
  }, [
    closeModal,
    onSuccessProps
  ]);

  useElementEvent(`#${button}`, openModal);

  return (
    <Modal
      className="order-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="order-modal-content">
        <OrderForm
          content={content}
          onSuccess={onSuccess}
        />
      </div>

      <Button
        aria-label="Close"
        className="ReactModal__Close"
        onClick={closeModal}
      >
        <Icon
          color="#afafaf"
          name="Close"
          width={32}
          height={32}
        />
      </Button>
    </Modal>
  );
};

OrderFormModal.propTypes = {
  button: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  onSuccess: PropTypes.func
};

export default memo(OrderFormModal);
