/* globals gtag */
import initOrderForm from '../shared/orderForm';
import initSliderCards from '../shared/sliderCards';

import initModalFriend from './modalFriend';
import initSliderMain from './sliderMain';
import initSliderModal from './sliderModal';
import initVideoModal from './videoModal';

const initObjectPage = ({ lang, object, content }) => {
  initSliderMain(object.sliderMain);
  initSliderModal(object.sliderModal);
  initSliderCards(object.similar.id, object.similar.count);

  if (object.videoModal.videoUrl) {
    initVideoModal(object.videoModal);
  }

  initModalFriend(object.share, lang, object.model.id);

  // init order form
  const onSuccess = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'send_request', {
        event_category: object.model.type,
        event_label: `${object.model.title} - ${object.model.ref}`,
        value: parseInt(object.model.price, 10) // TODO:
      });
    }
  };

  initOrderForm(content.order, object.model.id, onSuccess);
};

export default initObjectPage;
