import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ResultCard = ({
  house: {
    url,
    main_photo: image,
    city,
    title
  }
}) => (
  <a
    className="result-card"
    href={url}
  >
    {!!image && (
      <img
        src={image}
        alt={title}
      />
    )}

    {!!city && (
      <p>{city}</p>
    )}

    {!!title && (
      <h2>{title}</h2>
    )}
  </a>
);

ResultCard.propTypes = {
  house: PropTypes.shape({
    url: PropTypes.string.isRequired,
    main_photo: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default memo(ResultCard);
