import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import { getObjects } from '../../api';
import useApiCall from '../../hooks/useApiCall';
import useElementEvent from '../../hooks/useElementEvent';
import { appendToList } from '../../utils/tools';

const ObjectsLoader = ({
  lang,
  selected,
  type
}) => {
  const [page, setPage] = useState(2);

  const buttonRef = useRef(null);

  const onSuccess = useCallback((response) => {
    const { result } = response.data;
    appendToList('#houses-list', result.items);

    if (!result.has_next && buttonRef.current) {
      buttonRef.current.style.display = 'none';
    }

    setPage((prevPage) => prevPage + 1);
  }, []);

  const onSubmit = useCallback(() => {
    const options = { ...selected, lang, page };
    return getObjects(type, options);
  }, [lang, page, selected, type]);

  const {
    callback,
    processing
  } = useApiCall(onSubmit, onSuccess);

  useEffect(() => {
    buttonRef.current = document.querySelector('#sale-rental-load-more');
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.disabled = processing;
    }
  }, [processing]);

  useElementEvent(buttonRef.current, callback);

  return null;
};

ObjectsLoader.propTypes = {
  lang: PropTypes.string,
  selected: PropTypes.object,
  type: PropTypes.string
};

export default ObjectsLoader;
