import Swiper from 'swiper';

const initSliderValuation = (id) => (
  new Swiper(`${id} .swiper`, {
    speed: 600,
    simulateTouch: false
  })
);

export default initSliderValuation;
