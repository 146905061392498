import React from 'react';
import { createRoot } from 'react-dom/client';

import Filters from '../../components/Filters';

export default (lang, data) => {
  const container = document.getElementById(data.id);
  const root = createRoot(container);

  root.render(
    <Filters
      lang={lang}
      content={data.content}
      type={data.type}
      selected={data.selected}
      options={data.options}
    />
  );
};
