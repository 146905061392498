import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import useBooleanState from '../../../hooks/useBooleanState';

import useElementEvent from '../../../hooks/useElementEvent';
import ObjectSlider from '../../ObjectSliderModal';
import ObjectGallery from '../../ObjectGallery';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import Icon from '../../UI/Icon';

const ObjectSliderModal = ({
  buttonId,
  slides,
  title,
  gallery
}) => {
  const [active, setActive] = useState(null);

  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal
  } = useBooleanState();

  const {
    isOpen: isGalleryOpen,
    toggle: toggleGallery,
    close: closeGallery
  } = useBooleanState();

  useElementEvent(buttonId, openModal);

  const onClick = useCallback((slide, index) => {
    setActive(index);
    closeGallery();
  }, [closeGallery]);

  return (
    <Modal
      overlayClassName="object-modal-overlay"
      isOpen={isModalOpen}
      onRequestClose={closeModal}
    >
      <CSSTransition
        in={!isGalleryOpen}
        timeout={500}
      >
        <div className="object-slider">
          <div className="object-modal-content">
            <ObjectSlider
              active={active}
              slides={slides}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={isGalleryOpen}
        timeout={500}
      >
        <div className="object-gallery">
          <div className="object-modal-content">
            <ObjectGallery
              slides={slides}
              title={title}
              onClick={onClick}
            />
          </div>
        </div>
      </CSSTransition>

      <div className="object-modal-wrapper">
        <Button
          aria-label={gallery}
          className="object-modal-toggle"
          aria-pressed={String(isGalleryOpen)}
          onClick={toggleGallery}
        >
          <Icon
            name="Gallery"
            width={36}
            height={36}
            color="#FFF"
          />

          <span className="content-subtitle">
            {gallery}
          </span>
        </Button>

        <Button
          aria-label="Close"
          className="ReactModal__Close"
          onClick={isGalleryOpen ? closeGallery : closeModal}
        >
          <Icon
            name="Close"
            width={32}
            height={32}
            color="#FFF"
          />
        </Button>
      </div>
    </Modal>
  );
};

ObjectSliderModal.propTypes = {
  buttonId: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  gallery: PropTypes.string.isRequired
};

export default memo(ObjectSliderModal);
