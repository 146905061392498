import {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';

import { setCookie } from '../../utils';

const Cookie = () => {
  const [opened, setOpened] = useState(false);

  const cookie = useRef(null);
  const buttonAccept = useRef(null);
  const buttonClose = useRef(null);

  useEffect(() => {
    cookie.current = document.getElementById('cookie');

    if (!cookie.current) {
      return;
    }

    buttonAccept.current = document.getElementById('cookieAccept');

    if (buttonAccept.current) {
      buttonAccept.current.addEventListener('click', () => {
        setOpened(false);
        setCookie('cookie_consent', 'true', 10000);
      });
    }

    buttonClose.current = document.getElementById('cookieClose');
    if (buttonClose.current) {
      buttonClose.current.addEventListener('click', () => {
        setOpened(false);
      });
    }

    const timeout = setTimeout(() => setOpened(true), 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!cookie.current) {
      return;
    }

    if (opened) {
      cookie.current.classList.remove('hidden');
    } else {
      cookie.current.classList.add('hidden');
    }
  }, [opened]);

  return null;
};

export default memo(Cookie);
