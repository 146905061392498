import initSliderValuation from './slider';

const activeTabClass = 'valuation-tab-active';

const initValuationTabs = (sliderId) => {
  const valuationSwiper = initSliderValuation(sliderId);

  const tabs = document.querySelectorAll('.valuation-tab');
  tabs[0].classList.add(activeTabClass);

  Array.prototype.forEach.call(tabs, (tab) => {
    tab.addEventListener('click', (event) => {
      event.preventDefault();

      if (!tab.classList.contains(activeTabClass)) {
        const activeTab = document.querySelector(`.${activeTabClass}`);

        if (activeTab) {
          activeTab.classList.remove(activeTabClass);
        }

        tab.classList.add(activeTabClass);

        valuationSwiper.slideTo(tab.dataset.tabIndex);
      }
    });
  });
};

export default initValuationTabs;
