import React from 'react';
import { createRoot } from 'react-dom/client';

import OrderFormModal from '../../components/Modals/OrderFormModal';

const initOrderFormModal = (options, onSuccess) => {
  const container = document.getElementById(options.root);
  const root = createRoot(container);

  root.render(
    <OrderFormModal
      button={options.button}
      content={options.content}
      modal={options.modal}
      onSuccess={onSuccess}
    />
  );
};

export default initOrderFormModal;
