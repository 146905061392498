// eslint-disable-next-line import/prefer-default-export
export const styles = [{
  featureType: 'administrative',
  elementType: 'labels.text.fill',
  stylers: [{ color: '#444444' }]
}, {
  featureType: 'administrative.country',
  elementType: 'geometry.stroke',
  stylers: [{ visibility: 'off' }]
}, {
  featureType: 'landscape',
  elementType: 'all',
  stylers: [{ color: '#f2f2f2' }]
}, {
  featureType: 'poi',
  elementType: 'all',
  stylers: [{ visibility: 'off' }]
}, {
  featureType: 'road',
  elementType: 'all',
  stylers: [{ visibility: 'on' }, { hue: '#ff0000' }, { saturation: '-100' }]
}, {
  featureType: 'road.highway',
  elementType: 'all',
  stylers: [{ visibility: 'simplified' }]
}, {
  featureType: 'road.highway.controlled_access',
  elementType: 'geometry.stroke',
  stylers: [{ gamma: '0.98' }, { visibility: 'on' }]
}, {
  featureType: 'road.arterial',
  elementType: 'labels.icon',
  stylers: [{ visibility: 'off' }]
}, {
  featureType: 'transit',
  elementType: 'all',
  stylers: [{ visibility: 'on' }, { saturation: '-93' }]
}, {
  featureType: 'water',
  elementType: 'all',
  stylers: [{ color: '#a8d0dd' }, { visibility: 'on' }]
}];
