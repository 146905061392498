import { reloadMobileHandler, renderComponent } from '../../utils/tools';
import initSliderBlog from './slider';
import PostsLoader from './loader';

export default ({ lang, posts }) => {
  // FIXME:
  window.initSliderBlog = initSliderBlog;

  posts.forEach((post) => initSliderBlog(`#swiper-blog-${post}`));

  renderComponent(PostsLoader, { lang });

  // reload on screen resize
  reloadMobileHandler();
};
